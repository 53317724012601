// src/CertificatePage.js
import React from "react";
import { useParams } from "react-router-dom";
import students from "./students";
import './CertificatePage.css'; // Import the CSS file

const CertificatePage = () => {
  const { id } = useParams();
  const student = students.find((student) => student.id === id);

  if (!student) {
    return <h2>No certificate found</h2>;
  }

  // Paths derived from the unique ID
  const certificateImagePath = `/certificate/${id}.jpg`; // Image of the certificate
  const pdfPath = `/certificate/${id}.pdf`; // PDF file path

  return (
    <div className="certificate-container">
      <h1>Certificate for {student.name}</h1>
      
      <div className="student-info">
        {/* Student Face Image */}
        <img
          src={student.image}
          alt={student.name}
          className="certificate-image"
        />

        {/* Student Details */}
        <div className="student-details">
          <p><strong>Name:</strong> {student.name}</p>
          <p><strong>Course:</strong> {student.course}</p>
        </div>
      </div>

      {/* Certificate Image */}
      <div className="certificate-image-container">
        <img
          src={certificateImagePath}
          alt="Certificate"
          className="certificate-pdf-image"
        />
      </div>

      {/* Download Button */}
      <a
        href={pdfPath}
        download
        className="download-button"
      >
        Download PDF
      </a>
    </div>
  );
};

export default CertificatePage;
