// src/SearchPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import students from "./students";
import './SearchPage.css'; // Import the external CSS

const SearchPage = () => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault(); // Prevent page reload on form submission
    const student = students.find((student) => student.id === query.trim());
    if (student) {
      navigate(`/certificate/${student.id}`);
    } else {
      alert("No certificate found for this ID.");
    }
  };

  return (
    <div className="search-page">
    <div className="search-logo">
    <img src={"/images/mainlogo.png"} alt="Logo" className="main-logo" />
    </div>
      <form className="search-form" onSubmit={handleSearch}>
        <h1>Certificate Validator</h1>
        <input
          type="text"
          placeholder="Enter unique certificate ID"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="search-input"
        />
        <button type="submit" className="search-button">
          Search
        </button>
      </form>
    </div>
  );
};

export default SearchPage;
