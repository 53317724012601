// src/App.js
import React from "react";
import { Routes, Route, useParams } from "react-router-dom";
import CertificatePage from "./CertificatePage"; // Import the CertificatePage component
import SearchPage from "./SearchPage"; // Import the SearchPage component

// Wrapper to extract the id from URL and pass it to CertificatePage
const CertificateWrapper = () => {
  const { id } = useParams();
  return <CertificatePage id={id} />;
};

// App component for handling routing
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<SearchPage />} />
      <Route path="/certificate/:id" element={<CertificateWrapper />} />
      {/* Optional: Add a 404 Not Found route */}
      <Route path="*" element={<h2 className="error-message">Page Not Found</h2>} />
    </Routes>
  );
};

export default App;

